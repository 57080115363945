html,
body {
  font-family: "Libre Franklin", sans-serif;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  user-select: none;
  box-sizing: border-box;
  touch-action: none;
  -webkit-touch-callout: none;

  height: 100vh;
  height: -webkit-fill-available;
  height: fill-available;
}
textarea {
  font-family: "Libre Franklin", sans-serif;

}
* {
  -webkit-user-select: none; /* Safari */
  user-select: none !important;
  touch-action: manipulation;
}
#window {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  position: absolute;
  overflow: hidden;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  height: -webkit-fill-available;
  height: fill-available;
  /* background-color: bisque;  */
}
@media screen and (max-width: 600px) {
  #window {
    zoom: 0.6;
    /* overflow: visible; */

    /* overflow: hidden; */
    /* overscroll-behavior-y: none; */
  }
}

#background {
  background-size: 20px 20px;
  position: absolute;
  /* overflow: hidden; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #aaa 1px, transparent 1px),
    linear-gradient(to bottom, #aaa 1px, transparent 1px);
}

#entities {
  transform: translateZ(0);

  /* transition: transform 100ms; */
  will-change: transform;
}

#entities img {
  /* box-shadow: 2px 2px 10px black; */
  /* filter: drop-shadow(4px 4px 7px black); */
  /* opacity: 0.5; */
  /* will-change: filter; */
  /* box-shadow: inset 0 0 0 0.01px white; */
  /* mix-blend-mode: normal; */
  /* image-rendering: pixelated; */
}

* {
  color: #333;
  margin: 0;
  /* border: 1px red solid; */
}

img {
  /* position: absolute; */
  z-index: 1;
  /* mix-blend-mode: difference; */
  /* mix-blend-mode: exclusion; */
  pointer-events: none;
  user-select: none;
  /* transition: transform 0.05s; */
  /* transform: translate(-50%, -50%); */
}

.deleting img {
  pointer-events: all;
}
.deleting {
  z-index: 1;

  cursor: url(./assets/delete.ico), auto;
}

.deleting #entities img:hover {
  border: 3px red dashed;
  filter: drop-shadow(0px 0px 5px red);
}

.moving img {
  pointer-events: all;
}
.moving {
  z-index: 1;

  cursor: move;
}

.moving #entities img:hover {
  /* border: 3px greenyellow dashed; */
  /* filter: drop-shadow(0px 0px 5px greenyellow); */
}

#walker {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 10;
  filter: contrast(120%);
  mix-blend-mode: normal;
}
.speech {
  font-size: 50px;
  position: absolute;
  z-index: 10;
  transform: translate(-50%, -130px);
  /* filter: drop-shadow(0px 0px 2px #fff); */
}
.bubble {
  width: 120px;
  height: 120px;
  font-size: 50px;
  position: absolute;
  z-index: 9;
  filter: contrast(1.9);
  transform: translate(-50%, -150px);
  mix-blend-mode: normal;
}

#spawner{
  position: absolute;
  z-index: 10006;
  top: -50px;
  /* transform: translate(-50%, -50%); */
  width: 150px;
  height: 150px;
  /* border-radius: 100%; */
  /* opacity: 0.5; */
  /* border: 2px dashed rgba(100, 100, 100, 0.561) ; */
}
#spawner.shadow{
  z-index: 10;
}

.rotate{
  animation: rotate 10s linear infinite; 
}
@keyframes rotate{
  to{ transform: rotate(360deg); }
}

#close {
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  width: 32px;
  height: 32px;
  pointer-events: all;
  cursor: pointer;
}

.audio {
  position: absolute;
  opacity: 0.2;
  font-size: 60px;
  filter: blur(25px);
  position: "absolute";
}
